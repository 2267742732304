/* Libraries */
import React, {ReactElement} from 'react';
import {AppProps, NextWebVitalsMetric} from 'next/app';
import localFont from 'next/font/local';

/* Components */
import App from '@components/App';

/* global css */
import '../styles/main.scss';
import '@devlink/global.css';
import '../styles/vendor/icomoon.css';
import '../styles/vendor/nprogress.css';
import '@public/_nextassets_/fonts/fonts.css';
import 'intersection-observer';
import Head from 'next/head';
import ThirdPartyScripts from '@integrations/analytics';

export interface IMyAppProps extends AppProps {
  Component: any;
  dehydratedState: any;
  pageProps: any;
  fontClass: string;
}

// Define the local font
// @ts-ignore
const IndivisibleVariable = localFont({
  src: [
    {
      path: '../../public/_nextassets_/fonts/IndivisibleWebVariableRoman.woff2',
      weight: '300 700',
      style: 'normal',
    },
  ],
  variable: '--indivisible-font', // Define a CSS variable for use in styles
  display: 'swap', // Set the font-display strategy here
});

/* Test with using workers. My preliminary tests did not show an improvement, curious */
const MyApp = (props: IMyAppProps): ReactElement => (
  <>
    <Head>
      <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=2" />
    </Head>
    {/*
        There will be times a page might not require everything in "App", it adds a lot of
        base code. This is flexibile that depending on the page, we can use a different App file.
    */}
    <App {...props} fontClass={IndivisibleVariable.className} />
    <ThirdPartyScripts />
    <></>
  </>
);

/*
 * Can add google analytics here too
 * */
export const reportWebVital = (metric: NextWebVitalsMetric) => {
  window.metrics = [...(window.metrics || []), metric];
};

export default MyApp;
